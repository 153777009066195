<template>
  <div>
    <div class="nodata" v-if="isNull">
      <img class="kongimg" src="../../assets/abd/image/null.png" alt />
      <p class="asdasdqwe">{{error}}</p>
    </div>
    <md-popup v-model="isPopupShow" :mask-closable="closable">
      <div class="icdoling">
        <md-icon name="spinner" color="blue" size="lg" style="-webkit-filter:invert(1)"></md-icon>
      </div>
    </md-popup>
  </div>
</template>
<script>
import { interInsurance } from "@/api/xsqd/index";
export default {
  data() {
    return {
      closable: false,
      isPopupShow: true,
      url: "",
      error: "",
      isNull: false
    };
  },
  created() {
    this.url = window.location.href;
    if(this.url.indexOf('?')===-1){
         this.isPopupShow = false;
         this.error = "您的链接没有配置所需的参数！";
         this.isNull = true;
         return
     }
    let comid = this.getUrlParameter(this.url, "comid");
    let salecomid = this.getUrlParameter(this.url, "salecomid");
    let prodcode = this.getUrlParameter(this.url, "prodcode");
    let channelToken = this.getUrlParameter(this.url, "channelToken");
    this.getNewUrl(comid, salecomid, prodcode, channelToken);
  },
  methods: {
    getUrlParameter(url, param) {
      var pageUrl = url.split("?");
      var urlVariables = pageUrl[1].split("&");
      for (var i = 0; i < urlVariables.length; i++) {
        var parameterName = urlVariables[i].split("=");
        if (parameterName[0] === param) {
          return parameterName[1];
        }
      }
      return null;
    },
    getNewUrl(comid, salecomid, prodcode, channelToken) {
      let data = {
        comid: comid,
        salecomid: salecomid,
        prodcode: prodcode,
        channelToken: channelToken
      };
      interInsurance(data)
        .then(res => {
          if (res.data.code === 200) {
            this.isPopupShow = false;
            window.location.href = res.data.data;
          } else {
            this.error = res.data.msg;
            this.isNull = true;
            this.isPopupShow = false;
          }
        })
        .catch(err => {
          this.isPopupShow = false;
        });
    }
  }
};
</script>
<style lang="stylus" scoped>
/deep/ .icdoling .md-icon {
  width: 1.42rem;
  height: 1.42rem;
  line-height: 1.42rem;
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 4rem;
    height: 4rem;
  }
}
</style>